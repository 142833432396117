<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel
            title="List Surat Setoran Retribusi Daerah"
            class="panel panel-success"
        >

            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button variant="primary" to="/menu/ssrd/buat">
                                    <i class="fa fa-plus pr-1"></i> Tambah SSRD
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER 01 -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter By"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="filterBy"
                                                :options="fieldOptions"
                                                @change="changeFilterBy()"
                                            >
                                                <option slot="first" :value="'all'"
                                                    >All Data</option
                                                >
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group
                                        :label-cols="3"
                                        label="Filter"
                                        class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching "
                                    >
                                        <b-input-group size="md">
                                            <b-form-input
                                                v-model="filter"
                                                @keyup.enter="testFunc(filter)"
                                                placeholder="Type to Search"
                                                debounce="500"
                                            />
                                            <b-input-group-append>
                                                <b-btn
                                                    :disabled="!filter"
                                                    @click="filter = ''"
                                                    variant="info"
                                                    >Clear</b-btn
                                                >
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Sort"
                                        class="mb-1"
                                    >
                                        <b-input-group size="md">
                                            <b-form-select
                                                v-model="sortBy"
                                                :options="fieldOptions"
                                            >
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select
                                                :disabled="!sortBy"
                                                v-model="sortDesc"
                                                slot="append"
                                            >
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr class="my-2">
                            <b-row>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Perangkat PD"
                                        class="mb-1"
                                    >
                                        <v-select
                                            label="nama"
                                            :reduce="nama => nama.id"
                                            :options="PemerintahDaerah.perangkat"
                                            placeholder="-Pilih Perangkat-"
                                            v-model="perangkatpd"
                                            @input="inputPerangkat()"
                                        ></v-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" v-role:any="'Administrator|Maintenance'">
                                    <b-form-group
                                        :label-cols="3"
                                        label="Periode SSRD"
                                        class="mb-1"
                                    >
                                        <b-row>
                                            <b-col md="8">
                                                <v-select
                                                    label="nama"
                                                    :options="bulan"
                                                    :reduce="nama => nama.id"
                                                    placeholder="-Pilih Bulan-"
                                                    v-model="perangkat.bulan"
                                                    @input="inputPerangkat"
                                                ></v-select>
                                            </b-col>
                                            <b-col md="4">
                                                <v-select
                                                    :options="tahunOptions"
                                                    placeholder="-Pilih Tahun-"
                                                    v-model="perangkat.tahun"
                                                    @input="inputPerangkat"
                                                ></v-select>
                                            </b-col>
                                        </b-row>
                                    </b-form-group>
                                </b-col>
                                <b-col md="9">
                                    <b-form-group
                                        :label-cols="2"
                                        label="Status SSRD"
                                        class="mb-1"
                                    >
                                        <b-form-radio-group
                                            name="radio-sub-component"
                                            v-model="selected"
                                            class="pt-2 pb-0"
                                        >
                                            <b-form-radio @change="filterStatus('all')" value="all">
                                                <span class="font-weight-bold label label-warning">
                                                    Semua Status
                                                </span>
                                            </b-form-radio>
                                            <b-form-radio
                                                v-for="(item,
                                                index) in StatusAplikasi.ssrStatusAplikasiOptions"
                                                :key="index"
                                                @change="filterStatus(item.id)"
                                                :value="item.id"
                                            >
                                                <span
                                                    :class="
                                                        `font-weight-bold label label-${item.warna}`
                                                    "
                                                >
                                                    {{ item.nama }}
                                                </span>
                                            </b-form-radio>
                                        </b-form-radio-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER 01 -->
                        
                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <!-- Data Table & Pagination -->
            <b-table
                show-empty
                small
                id="masTable"
                ref="masTable"
                head-variant="light"
                :hover="true"
                :busy.sync="isBusy"
                :items="myGetData"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive
                bordered
            >
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- ... -->
                <template v-slot:table-colgroup="scope">
                    <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                            width: field.key === 'actions' ? '105px' : '',
                        }"
                    />
                </template>
                <template #head()="data">
                    <div class="" v-html="data.label"></div>
                </template>
                <!-- ... -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- PENGATURAN KOLOM 
                -------------------------------------------------- -->

                <template #cell(spt)="data">
                    <div v-if="data.item.spt">
                        {{ data.item.spt.perangkat_pd.nama }}<br>
                        {{ data.item.spt.unit_pd.nama }}
                    </div>
                </template>
                <template #cell(no_ssr)="data">
                    {{ data.item.no_ssr }}<br>
                    {{ data.item.tgl_ssr }}
                </template>
                <template #cell(no_sts)="data">
                    {{ data.item.no_sts }}<br>
                    {{ data.item.tgl_sts }}
                </template>
                <template #cell(nilai_terutang)="data">
                    {{ data.item.nilai_terutang.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}
                </template>
                <template #cell(status_bayar)="data">
                    <div class="text-center">
                        <div
                            v-if="data.item.status_bayar === 0"
                            class="label label-danger">
                            Belum Bayar
                        </div>
                        <div v-else class="label label-success">
                            Sudah Bayar
                        </div>
                    </div>
                </template>
                <template #cell(npwrd)="data">
                    <div v-if="data.item.spt" style="width: 200px">
                        <div>
                            {{ data.item.spt.npwrd.nama }}
                        </div>
                        <div>
                            {{ data.item.spt.npwrd.no_pokok }}
                        </div>
                    </div>
                </template>
                <template v-slot:cell(actions)="data">
                    <b-dropdown
                        split
                        class="d-flex align-item-center"
                        size="sm"
                        variant="info"
                    >
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="editButton(data.item.id)" disabled
                            ><i class="fa fa-edit"></i> Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                            @click="swalNotification('error', data.item.id)"
                            disabled
                            ><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item
                        >
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item 
                            @click="showPdf(data.item.no_sts)"
                            ><i class="fa fa-file-pdf"></i>
                            SSRD</b-dropdown-item
                        >
                        <b-dropdown-item v-if="data.item.spt.jenis_form == 'Khusus Tiket Masuk'"
                            @click="
                                getPdfTiket(data.item.spt.id)
                            ">
                            <i class="fa fa-file-pdf"></i> Tiket Masuk
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- end of table -->

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select
                                :options="pageOptions"
                                style="width: 100px;"
                                v-model="perPage"
                            />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input
                                :disabled="true"
                                v-model="totalRows"
                                style="width: 100px;"
                                class="text-right"
                            />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="md"
                        class="my-0 "
                    ></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
            <!-- end of Table -->
        </panel>
        <!-- End of Modal -->
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";
import hapusStorage from '@/helper/hapusLocalStore'
import { mapState } from 'vuex'

export default {
    mixins: [hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,

            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: 'text-right'
                },
                {
                    key: "spt",
                    label: "PERANGKAT & UNIT",
                    sortable: true,
                },
                {
                    key: "npwrd",
                    label: "NPWRD",
                    sortable: true,
                },
                {
                    key: "no_ssr",
                    label: "NO./TGL. SSRD",
                    sortable: true,
                },
                {
                    key: "no_sts",
                    label: "NO./TGL. KODE BAYAR",
                    sortable: true,
                },
                {
                    key: "metode_bayar",
                    label: "METODE BAYAR",
                    sortable: true,
                },
                // {
                //     key: "penerima",
                //     label: "PENERIMA",
                //     sortable: true,
                // },
                // {
                //     key: "keterangan",
                //     label: "KETERANGAN",
                //     sortable: false,
                // },
                {
                    key: "nilai_terutang",
                    label: "BIAYA RETRIBUSI (Rp.)",
                    sortable: true,
                    tdClass: 'text-right'
                },
                {
                    key: "status_bayar",
                    label: "STATUS BAYAR",
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "Opsi",
                },
            ],
            showModalData: false,
            isLoading: false,
            pdfUrl: "",
            isLoading: false,
            showLoader: false,
            bulan: [
                {
                    id: "01",
                    nama: "Januari"
                },
                {
                    id: "02",
                    nama: "Februari"
                },
                {
                    id: "03",
                    nama: "Maret"
                },
                {
                    id: "04",
                    nama: "April"
                },
                {
                    id: "05",
                    nama: "Mei"
                },
                {
                    id: "06",
                    nama: "Juni"
                },
                {
                    id: "07",
                    nama: "Juli"
                },
                {
                    id: "08",
                    nama: "Agustus"
                },
                {
                    id: "09",
                    nama: "September"
                },
                {
                    id: "10",
                    nama: "Oktober"
                },
                {
                    id: "11",
                    nama: "November"
                },
                {
                    id: "12",
                    nama: "Desember"
                }
            ],
            tahunOptions: [],
            selected: 'all',
            perangkatpd: null,
            statuspt: null,
            perangkat: {
                bulan: null,
                tahun: null
            }
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
        ...mapState(["StatusAplikasi", "PemerintahDaerah"])
    },
    mounted () {
        this.$store.dispatch("StatusAplikasi/dataSsrStatusAplikasi");
        this.$store.dispatch("PemerintahDaerah/getPerangkat");
        this.getTahun();
    },
    methods: {
        inputPerangkat() {
            if (!this.perangkat.tahun) {
                this.perangkat.tahun = new Date().getFullYear()
            }
            this.reload()
        },
        filterStatus(value) {
            this.statusspt = value;
            this.reload();
        },
        getTahun() {
            const now = new Date();
            this.tahunOptions.push(now.getFullYear() - 1);
            this.tahunOptions.push(now.getFullYear());
        },
        // data table
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get("/api/transaksi/ssr", {
                params: {
                    page: ctx.currentPage,
                    perpage: ctx.perPage,
                    sortby: ctx.sortBy,
                    sortdesc: ctx.sortDesc,
                    filter: ctx.filter,
                    filterby: this.filterBy,
                    statu_bayar: this.statusspt,
                    perangkatpd: this.perangkatpd,
                    bulan: this.perangkat.bulan,
                    tahun: this.perangkat.tahun
                },
            });
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Aksi Tidak Diizinkan",
                text: "Data pada tabel ini tidak dapat dihapus.",
                icon: "error",
                confirmButtonText: "OKE",
                confirmButtonClass: "btn btn-primary",
                timer: 5000,
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditSSRD", params: { id: index } });
        },
        showPdf(id) {
            this.getPdf(id)
        },
         // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // view pdf
        getPdf(id) {
            this.showLoader = true
            axios
                .get("/api/view-pdf/surat/ssr/by-sts/" + id, {
                    responseType: "arraybuffer",
                    timeout: 10000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.showLoader = false
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        getPdfTiket(id) {
            this.showLoader = true
            axios
                .get("/api/view-pdf/surat/ssr/tiket-masuk/spt-" + id, {
                    responseType: "arraybuffer",
                    timeout: 30000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    console.log(error)
                    this.showLoader = false
                    // if (error.response.status === 401) {
                    //     // hapus storage mixins
                    //     this.clearAll()
                    // }
                });
        },
    },
};
</script>
